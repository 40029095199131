import Experience from "./Experience";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default class Camera {
  constructor() {
    this.experience = new Experience();

    this.debug = this.experience.debug;

    this.canvas = this.experience.canvas;
    this.scene = this.experience.scene;
    this.size = this.experience.size;
    this.fov = {
      x: this.size.width > 678 ? 75 : 75,
    };

    this.setInstance();
    this.setControls();

    if (this.debug.active) {
      this.debugActive();
    }
  }

  setInstance() {
    this.instanceGroup = new THREE.Group();
    this.scene.add(this.instanceGroup);
    this.instance = new THREE.PerspectiveCamera(
      this.fov.x,
      this.size.width / this.size.height,
      0.01,
      100
    );
    this.instanceGroup.add(this.instance);

    if (this.size.mobileSize) {
      this.instance.position.set(
        0.9659210036680098,
        2.204493923358167,
        8.021701670659581
      );
      this.instance.rotation.set(
        -0.20062906567197134,
        -0.14683889324590196,
        -0.029745923761093796
      );
    } else {
      this.instance.position.set(
        1.8750664037978062,
        1.751320731036853,
        5.029407143403999
      );
      this.instance.rotation.set(
        -0.16631240223779123,
        0.0046421849681760365,
        0.0007792479054948689
      );
    }
  }

  setControls() {}

  resize() {
    this.instance.aspect = this.size.width / this.size.height;
    this.instance.updateProjectionMatrix();
  }

  update() {
    if (this.controls) {
      this.controls.update();
    }
  }

  debugActive() {
    this.cameraDebug = this.debug.gui.addFolder("camera");

    this.cameraDebug
      .add(this.instance.position, "x")
      .min(-50)
      .max(50)
      .step(0.001)
      .name("cameraX");
    this.cameraDebug
      .add(this.instance.position, "y")
      .min(-50)
      .max(50)
      .step(0.001)
      .name("cameraY");
    this.cameraDebug
      .add(this.instance.position, "z")
      .min(-50)
      .max(50)
      .step(0.001)
      .name("cameraZ");
    this.cameraDebug
      .add(this.fov, "x")
      .min(0)
      .max(100)
      .step(0.001)
      .name("cameraFOV")
      .onFinishChange(() => {
        this.instance.fov = this.fov.x;
        this.instance.aspect = this.size.width / this.size.height;
        this.instance.updateProjectionMatrix();
      });
  }
}
