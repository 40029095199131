import * as THREE from "three";
import Experience from "./Experience";

import gsap from "gsap";

export default class World {
  constructor() {
    this.experience = new Experience();

    this.debug = this.experience.debug;
    this.size = this.experience.size;
    this.time = this.experience.time;

    this.scene = this.experience.scene;
    this.sizes = this.experience.size;
    this.resources = this.experience.resources;
    this.camera = this.experience.camera.instance;

    //? HTML
    this.html = this.experience.html;
    this.aboutCon = this.experience.html.aboutCon;

    this.informationCon = this.experience.html.infomationCon;
    this.projectCon = this.experience.html.projectCon;

    //? html buttons
    this.btnCon = this.html.btnCon;
    this.closeAboutBtn = this.html.closeAboutBtn;

    this.aboutBtn = this.html.aboutBtn;
    this.projectBtn = this.html.projectBtn;

    this.sceneGroup = new THREE.Group();

    this.debugSettings = {
      objectActive: true,
      myProperty: () => {
        if (this.scene.background) {
          this.scene.background = false;
        } else {
          this.scene.background = this.environmentTexture;
        }
      },

      stopObjectRotation: () => {
        if (this.debugSettings.objectActive) {
          this.debugSettings.objectActive = false;
        } else {
          this.debugSettings.objectActive = true;
        }
      },
    };

    this.setUpScene();
  }

  setUpScene() {
    this.resources.on("resourcesReady", () => {
      this.environmentTexture = this.resources.items.environmentTexture;
      this.environmentTexture.SRGBColorSpace = THREE.SRGBColorSpace;
      this.scene.environment = this.environmentTexture;
      // this.scene.background = this.environmentTexture;
      // this.scene.backgroundBlurriness = 0.2;

      this.bakedMain = this.resources.items.bakedMain;
      this.bakedMain.flipY = false;
      this.bakedMain.colorSpace = THREE.SRGBColorSpace;

      this.bakedModels = this.resources.items.bakedModels;
      this.bakedModels.flipY = false;
      this.bakedModels.colorSpace = THREE.SRGBColorSpace;

      this.cigaretteBaked = this.resources.items.cigaretteBaked;
      this.cigaretteBaked.flipY = false;
      this.cigaretteBaked.colorSpace = THREE.SRGBColorSpace;

      this.bg = this.resources.items.bg;
      this.bg.flipY = false;
      this.bg.colorSpace = THREE.SRGBColorSpace;

      this.wallpapers = this.resources.items.wallpapers;
      this.wallpapers.flipY = false;
      this.wallpapers.colorSpace = THREE.SRGBColorSpace;

      this.videoMaterial = this.setUpLoadingScreen("textures/screen-2.mp4");

      this.model = this.resources.items.model;

      this.bakedMainMaterial = new THREE.MeshBasicMaterial({
        map: this.bakedMain,
      });

      this.bakedModelsMaterial = new THREE.MeshBasicMaterial({
        map: this.bakedModels,
      });

      this.greenLedMaterial = new THREE.MeshBasicMaterial({
        color: 0x9ecca4,
      });

      this.whiteLedMaterial = new THREE.MeshBasicMaterial({
        color: 0x98ded9,
      });

      this.purpleLedMaterial = new THREE.MeshBasicMaterial({
        color: 0xffa1f5,
      });

      this.model.scene.traverse((e) => {
        if (e.type === "Mesh") {
        }
        if (e.name.startsWith("baked")) {
          e.material = this.bakedMainMaterial;
        }

        if (e.name.startsWith("model")) {
          e.material = this.bakedModelsMaterial;
        }

        if (e.name.startsWith("cigrette")) {
          e.material = new THREE.MeshBasicMaterial({
            map: this.cigaretteBaked,
          });
        }

        if (e.name.startsWith("monitor")) {
          e.material = this.videoMaterial;
        }

        if (e.name.startsWith("led_white")) {
          e.material = this.whiteLedMaterial;
          this.ledWhite = e;
        }

        if (e.name.startsWith("led_green")) {
          e.material = this.greenLedMaterial;
          this.ledGreen = e;
        }

        if (e.name.startsWith("wallpaper")) {
          e.material = new THREE.MeshBasicMaterial({
            map: this.wallpapers,
          });
        }

        if (e.name.startsWith("led_purple")) {
          e.material = this.purpleLedMaterial;
          this.ledPurple = e;
        }

        if (e.name.startsWith("bg")) {
          e.material = new THREE.MeshBasicMaterial({
            map: this.bg,
            transparent: true,
            opacity: 1,
          });
        }
      });

      // this.model.scene.scale.set(0, 0, 0);

      // gsap.to(this.model.scene.scale, {
      //   duration: 2.5,
      //   x: 1,
      //   y: 1,
      //   z: 1,
      //   delay: 1,
      //   ease: "ease-out",
      // });

      // gsap.to(this.model.scene.rotation, {
      //   duration: 2.5,

      //   y: -6.25,
      //   // z: 1,
      //   delay: 1,
      //   ease: "ease-out",
      // });

      this.scene.add(this.model.scene);

      this.scene.fog = new THREE.Fog(0x000000, 0, 40);

      //? button click

      this.btnCon.addEventListener("click", (e) => {
        this.informationCon.style.display = "none";
        this.projectCon.style.display = "none";
        if (e.target.classList.contains("about-btn")) {
          this.aboutCon.style.background = "hsla(210, 100%, 65%, 0.75)";
          document.querySelector(".con-title").textContent = "About Us";
          this.informationCon.style.display = "flex";
          this.aboutCon.style.opacity = "1";
          this.aboutCon.style.display = "flex";
        }

        if (e.target.classList.contains("project-btn")) {
          document.querySelector(".con-title").textContent = "Loud Projects";
          this.aboutCon.style.background = "hsla(87, 46%, 62%, 0.5)";

          this.projectCon.style.display = "flex";
          this.aboutCon.style.opacity = "1";
          this.aboutCon.style.display = "flex";
        }
      });

      this.closeAboutBtn.addEventListener("click", (e) => {
        gsap.to(".about-con", {
          duration: 1,
          opacity: 0,
          display: "none",
        });

        //? puzzle
      });
      this.debugActive();
    });
  }

  setUpLoadingScreen(video) {
    this.monitor = document.createElement("video");

    this.monitor.muted = true;
    this.monitor.controls = true;
    this.monitor.playsInline = true;
    this.monitor.autoplay = true;
    this.monitor.loop = true;
    this.monitor.currentTime = 1;
    this.monitor.allowsFullscreen = false;
    this.monitor.preload = "metadata";

    this.monitor.controlsList = "nofullscreen";
    this.monitor.src = video;
    document.querySelector("body").append(this.monitor);

    this.monitor.style.position = "fixed";
    this.monitor.style.top = 0;
    this.monitor.style.right = 0;
    this.monitor.style.zIndex = 0;
    this.monitor.style.maxWidth = "5px";
    this.monitor.style.maxHeight = "5px";
    this.monitor.style.opacity = 0;
    this.monitor.style.pointerEvents = "none";

    this.monitorTexture = new THREE.VideoTexture(this.monitor);
    this.monitorTexture.colorSpace = THREE.SRGBColorSpace;

    this.monitorTexture.flipY = false;

    return new THREE.MeshBasicMaterial({
      map: this.monitorTexture,
    });
  }

  debugActive() {
    if (this.experience.debug.active) {
      this.worldFolder = this.experience.debug.gui.addFolder("world-Debug");

      this.worldFolder
        .add(this.cube.material, "metalness")
        .min(0)
        .max(1)
        .step(0.05);
      this.worldFolder
        .add(this.cube.material, "roughness")
        .min(0)
        .max(1)
        .step(0.05);

      this.worldFolder
        .add(this.scene, "backgroundBlurriness")
        .min(0)
        .max(1)
        .step(0.05);

      this.worldFolder
        .add(this.scene, "environmentIntensity")
        .min(0)
        .max(10)
        .step(0.05);

      this.worldFolder
        .add(this.debugSettings, "myProperty")
        .name("Background-Opacity");

      this.worldFolder
        .add(this.debugSettings, "stopObjectRotation")
        .name("ObjectRotation");

      if (this.plane) {
        this.worldFolder
          .add(this.groundMirror.position, "y")
          .min(-10)
          .max(10)
          .step(0.01);

        this.worldFolder
          .add(this.plane.position, "y")
          .min(-10)
          .max(10)
          .step(0.01);

        this.worldFolder
          .add(this.plane.material, "opacity")
          .min(0)
          .max(1)
          .step(0.01);

        this.worldFolder.addColor(this.plane.material, "color");
      }
    }
  }

  update() {
    if (this.debugSettings.objectActive) {
    }
    //? updating points inside the scene
  }
}
