import Experience from "./Experience";
import gsap from "gsap";

export default class HTML {
  constructor() {
    this.experience = new Experience();
    this.body = document.querySelector("body");
    this.canvas = this.experience.canvas;
    this.resources = this.experience.resources;
    this.mobile = this.experience.size.mobileSize;

    //! html

    //? animate background
    this.bg = document.createElement("div");
    this.bg.classList.add("bg");
    this.bgContent = `
    <div class="img-con">

      <img src="/textures/guy-main-logo.png" alt="logo">

      </div>
      <svg
  class="container" 
  x="0px" 
  y="0px"
  viewBox="0 0 55 23.1"
  height="23.1"
  width="55"
  preserveAspectRatio='xMidYMid meet'
>
  <path
    class="track" 
    fill="none" 
    stroke-width="4" 
    pathlength="100"
    d="M26.7,12.2c3.5,3.4,7.4,7.8,12.7,7.8c5.5,0,9.6-4.4,9.6-9.5C49,5,45.1,1,39.8,1c-5.5,0-9.5,4.2-13.1,7.8l-3.4,3.3c-3.6,3.6-7.6,7.8-13.1,7.8C4.9,20,1,16,1,10.5C1,5.4,5.1,1,10.6,1c5.3,0,9.2,4.5,12.7,7.8L26.7,12.2z"
  />
  <path
    class="car" 
    fill="none" 
    stroke-width="4" 
    pathlength="100"
    d="M26.7,12.2c3.5,3.4,7.4,7.8,12.7,7.8c5.5,0,9.6-4.4,9.6-9.5C49,5,45.1,1,39.8,1c-5.5,0-9.5,4.2-13.1,7.8l-3.4,3.3c-3.6,3.6-7.6,7.8-13.1,7.8C4.9,20,1,16,1,10.5C1,5.4,5.1,1,10.6,1c5.3,0,9.2,4.5,12.7,7.8L26.7,12.2z"
  />
</svg>


      `;
    this.bg.innerHTML = this.bgContent;
    this.body.appendChild(this.bg);

    this.navImage = document.createElement("div");
    this.navImage.classList.add("nav-con");
    this.navImage.innerHTML = `
           <img src="/textures/navigation.png" alt="" />

    `;

    this.body.append(this.navImage);

    //? buttons

    this.btnCon = document.createElement("div");
    this.btnCon.classList.add("button-container");

    this.aboutBtn = document.createElement("button");
    this.aboutBtn.classList.add("button");
    this.aboutBtn.classList.add("about-btn");
    this.aboutBtn.innerText = "ABOUT";

    this.projectBtn = document.createElement("button");
    this.projectBtn.classList.add("button");
    this.projectBtn.classList.add("project-btn");
    this.projectBtn.innerText = "PROJECTS";

    //! end HTML

    this.btnCon.appendChild(this.aboutBtn);
    this.btnCon.appendChild(this.projectBtn);

    //?
    this.body.appendChild(this.btnCon);

    //? aboutCon

    this.aboutCon = document.createElement("div");
    this.aboutCon.classList.add("con-design");
    this.aboutCon.classList.add("about-con");
    this.closeAboutBtn = document.createElement("div");
    this.closeAboutBtn.classList.add("close-btn");

    this.aboutCon.innerHTML = `
      <div class=" close-about-con close-con">
        <h3 class="con-title">About Us</h3>
      </div>

      <div class="information-con">
        <p>
            LOUD is a Boston-bred, independent, community based lifestyle brand, media company, and multimedia content provider focusing on the production of high-quality programming - with a playful, yet professional spin. 
        </p>

        <p>
        LMTV’s goal is to create systemic change by providing public information to our audiences. Positioning ourselves as a public interest service-learning organization that provides transformative justice in advocacy of our Black & Brown communities.
        </p>


        <hr>
        <h3>
           You Aint Loud
        </h3>
        <p>
          You Aint Loud is an afro inner city centered
          community proudly committed
          to uprooting racism and seeding sovereignty in the cannabis industry through inclusion.
          you're now in rotation 
          smoke the competition
        </p>
      </div>

      <div class="projects-con-main">
        <div class="project-con">
          <img src="/textures/video-2.webp" alt="loud-talk-video" />
          <div class="project-p-con world-right-con">
            <div class="project-title">Loud Documentary & Loud X Cookies</div>
            <p>
            Our documentary series takes you on a journey through the many realms of the cannabis industry. 
            </p>
            <div class="project-description"><a href="https://video.wixstatic.com/video/ff6a7d_bf33747f97a14887afa1d594399bbc98/480p/mp4/file.mp4">View Video</a></div>          
          </div>
        </div>


         <div class="project-con">
          <img src="/textures/video-1.webp" alt="" />
          <div class="project-p-con world-right-con">
            <div class="project-title">Loud Talks</div>
            <p>
            Our collection of short-format, deep-impact interviews that resonate with the pulse of inspiration and insight.
            </p>
            <div class="project-description"><a href="https://www.youtube.com/watch?v=j3rI-JtLR1Y&list=TLGGa1YJA-5JS0UwMzA5MjAyNA&t=8s">View Video</a></div>          
          </div>
        </div>

        <div class="project-con">
          <img src="/textures/video-3.webp" alt="" />
          <div class="project-p-con world-right-con">
            <div class="project-title">Loud Experience</div>
            <p>
           From behind the scenes to insightful lectures, these exclusives come in various forms, each offering a unique and Loud Experience
            </p>
            <div class="project-description"><a href="https://www.youtube.com/watch?v=iiqGsbTgOYU&list=TLGGFqqW2EeYZ8cwMzA5MjAyNA&t=9s">View Video</a></div>  
          </div>
        </div>
      </div>


   
    `;
    this.body.append(this.aboutCon);
    this.infomationCon = document.querySelector(".information-con");
    this.projectCon = document.querySelector(".projects-con-main");
    document.querySelector(".close-about-con").append(this.closeAboutBtn);

    //? socials

    this.socialCon = document.createElement("div");
    this.socialCon.classList.add("social-con");

    this.socialCon.innerHTML = `
      <a href="https://www.instagram.com/LOUDMINDSONTV"><svg class="icon" width="64px" height="64px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 5H9.5C7.29086 5 5.5 6.79086 5.5 9V15C5.5 17.2091 7.29086 19 9.5 19H15.5C17.7091 19 19.5 17.2091 19.5 15V9C19.5 6.79086 17.7091 5 15.5 5Z" stroke="#ffffff" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 15C10.8431 15 9.5 13.6569 9.5 12C9.5 10.3431 10.8431 9 12.5 9C14.1569 9 15.5 10.3431 15.5 12C15.5 12.7956 15.1839 13.5587 14.6213 14.1213C14.0587 14.6839 13.2956 15 12.5 15Z" stroke="#ffffff" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"></path> <rect x="15.5" y="9" width="2" height="2" rx="1" transform="rotate(-90 15.5 9)" fill="#ffffff"></rect> <rect x="16" y="8.5" width="1" height="1" rx="0.5" transform="rotate(-90 16 8.5)" stroke="#ffffff" stroke-linecap="round"></rect> </g></svg></a>
      <a href="https://www.youtube.com/LOUDMINDSTV" ><svg class="icon" width="64px" height="64px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M18.168 19.0028C20.4724 19.0867 22.41 17.29 22.5 14.9858V9.01982C22.41 6.71569 20.4724 4.91893 18.168 5.00282H6.832C4.52763 4.91893 2.58998 6.71569 2.5 9.01982V14.9858C2.58998 17.29 4.52763 19.0867 6.832 19.0028H18.168Z" stroke="#ffffff" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.008 9.17784L15.169 11.3258C15.3738 11.4454 15.4997 11.6647 15.4997 11.9018C15.4997 12.139 15.3738 12.3583 15.169 12.4778L12.008 14.8278C11.408 15.2348 10.5 14.8878 10.5 14.2518V9.75184C10.5 9.11884 11.409 8.77084 12.008 9.17784Z" stroke="#ffffff" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></a>

    `;

    this.body.append(this.socialCon);

    this.resources.on("resourcesReady", () => {
      gsap.to(".bg", {
        duration: 2,
        opacity: 0,
        y: 0,
        ease: "ease-out",
        display: "none",
        onComplete: () => {
          gsap.to(this.navImage, {
            duration: 0.5,
            opacity: 1,
          });

          gsap.to(this.btnCon, {
            duration: 1,
            opacity: 1,
            y: 0,
            x: 0,
          });

          gsap.to(this.socialCon, {
            duration: 1,
            opacity: 1,
            y: 0,
            x: 0,
          });
        },
      });
    });
  }

  update() {}
}
