export default [
  {
    name: "environmentTexture",
    type: "cubeTextureLoader",
    path: [
      "/textures/envTexture/px.png",
      "/textures/envTexture/nx.png",
      "/textures/envTexture/py.png",
      "/textures/envTexture/ny.png",
      "/textures/envTexture/pz.png",
      "/textures/envTexture/nz.png",
    ],
  },

  { name: "model", type: "gltfLoader", path: "/models/model.glb" },

  {
    name: "bakedMain",
    type: "textureLoader",
    path: "/textures/room_background.webp",
  },

  {
    name: "bakedModels",
    type: "textureLoader",
    path: "/textures/models_baked2.webp",
  },

  {
    name: "bg",
    type: "textureLoader",
    path: "/textures/bg-2.png",
  },

  {
    name: "wallpapers",
    type: "textureLoader",
    path: "/textures/wallpapers.webp",
  },

  {
    name: "cigaretteBaked",
    type: "textureLoader",
    path: "/textures/cigrette_baked.webp",
  },
  //? TEXTURES
];
